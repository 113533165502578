@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #FFF;
}

.debug{
    border:4px solid #a3ff00;
}

.header_calculed {
    height: calc(100% - 56px );
}
